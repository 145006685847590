import MarkdownIt from 'markdown-it'

// Sheet key provided by Google
export const SHEET_KEY = '1EuMeormXZAxJH9P0PCmtB25lTOc4wMhuCcQ1uxQa0Gg'
// tab id provided by Google
const tab = 'od6'
// title of sheet column with text content
const FINAL_TEXT_COLUMN = 'text'

const markdownFormatter = new MarkdownIt({
  html: true,
  typographer: true,
})

const fetchAndParseTab = async (tabId) => {
  try {
    const res = await fetch(
      `https://spreadsheets.google.com/feeds/list/${SHEET_KEY}/${tabId}/public/values?alt=json`
    )
    const { feed } = await res.json()
    const output = {}
    const title = feed.title.$t.toLowerCase()

    if (feed.entry) {
      feed.entry.forEach((item) => {
        if (item.gsx$id && item.gsx$id.$t) {
          const id = `${item.gsx$id.$t.toLowerCase().trim()}`
          let value

          try {
            value = item[`gsx$${FINAL_TEXT_COLUMN}`].$t
          } catch (err) {
            value = ''
          }
          if (id.includes('intro')) {
            output[id] = markdownFormatter.render(value)
          } else output[id] = markdownFormatter.renderInline(value)
        }
      })
    }
    return { data: output, title }
  } catch (err) {
    console.error(err)
    return { data: {} }
  }
}

// main()
export const fetchStrings = async () => {
  const { data } = await fetchAndParseTab(tab)
  return data
}
