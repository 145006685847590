import { gsap } from 'gsap'

export const marquee = () => {
  const logos = document.querySelector('#marquee')
  const container = document.querySelector('#marquee-container')
  const containerWidth = container.offsetWidth

  for (let i = 0; i < 2; i++) {
    const clone = logos.cloneNode(true)
    clone.id = `marquee-${i}`
    container.appendChild(clone)
  }

  gsap.to('#marquee-container', {
    x: containerWidth * -1,
    duration: 40,
    ease: 'none',
    repeat: -1,
  })
}
