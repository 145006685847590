import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export function scrollAnimation() {
  gsap.registerPlugin(ScrollTrigger)
  const logo = gsap.timeline({
    scrollTrigger: {
      trigger: '#intro-container',
      start: 0,
      end: 'top top',
      scrub: 0,
      markers: false,
    },
  })
  logo
    .fromTo('#splash', { opacity: 1 }, { opacity: 0 })
    .fromTo(
      '#logo_new',
      { opacity: 1 },
      {
        opacity: 0,
        y: -100,
      },
      0
    )
    .fromTo(
      '#logo_futures_hq',
      { opacity: 1 },
      {
        opacity: 0,
        y: 1000,
      },
      0
    )
    .fromTo(
      '#scroll',
      { opacity: 1 },
      {
        opacity: 0,
        duration: 0.05,
      },
      0
    )
    .fromTo(
      '#intro-container',
      { opacity: 0 },
      {
        opacity: 1,
      },
      '=-10%'
    )
    .fromTo(
      '.header__buttons',
      {
        opacity: 0,
        pointerEvents: 'none',
      },
      {
        opacity: 1,
        pointerEvents: 'all',
      },
      '<'
    )
    .fromTo(
      '.header__circle',
      {
        opacity: 0,
        pointerEvents: 'none',
      },
      {
        opacity: 1,
        pointerEvents: 'all',
      },
      '<'
    )

  const photoStackLeft = gsap.timeline({
    scrollTrigger: {
      trigger: window.innerWidth >= 900 ? '#photos' : '#photo-stack-left',
      start: 'top bottom',
      end: 'top top',
      scrub: 0,
      markers: false,
    },
  })
  photoStackLeft
    .fromTo(
      '#photo-stack-left div:nth-child(2)',
      {
        y: '100%',
      },
      { y: 0 },
      0
    )
    .fromTo(
      '#photo-stack-left div:nth-child(3)',
      {
        y: '200%',
      },
      { y: 0 },
      0
    )

  const photoStackRight = gsap.timeline({
    scrollTrigger: {
      trigger: window.innerWidth >= 900 ? '#photos' : '#photo-stack-right',
      start: 'top bottom',
      end: 'top top',
      scrub: 0,
      markers: false,
    },
  })
  photoStackRight.fromTo(
    '#photo-stack-right div:nth-child(2)',
    {
      y: '100%',
    },
    { y: 0 },
    0
  )

  const headingAnimation = (triggerEl) => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: triggerEl,
        start: 'top bottom',
        end: 'center center',
        scrub: 0,
        markers: false,
      },
    })
    tl.fromTo(
      `${triggerEl} span:first-child`,

      {
        x: '-15%',
      },
      { x: '0' },
      0
    )
      .fromTo(
        `${triggerEl} span:nth-child(2)`,

        {
          x: '15%',
        },
        { x: '0' },
        0
      )
      .fromTo(
        `${triggerEl} span:nth-child(3)`,
        {
          x: '-30%',
        },
        { x: '0' },
        0
      )
  }

  headingAnimation('#get-involved-heading')
  headingAnimation('#help-heading')
}
