import { hideModal } from './modal'
const form = document.querySelector('#modal-form')
const requiredInputs = document.querySelectorAll('#modal-form input:required')
const formHeading = document.querySelector('#form-heading')
const modalInner = document.querySelector('#modal-inner')

const createErrorMessages = () => {
  const inputs = document.querySelectorAll('#modal-form input')
  inputs.forEach((input) => {
    const id = input.id
    const errorMessage = document.createElement('span')
    errorMessage.classList.add('modal__error')
    errorMessage.setAttribute('aria-live', 'polite')
    errorMessage.setAttribute('id', `${id}-error`)
    form.insertBefore(errorMessage, input.nextElementSibling)
  })
}

const showError = (input) => {
  const id = input.id
  const error = document.querySelector(`#${id}-error`)
  if (input.validity.valueMissing) {
    error.textContent = 'This field is required'
  } else if (input.validity.typeMismatch) {
    error.textContent = 'Incorrect e-mail format'
  }
  error.className = 'modal__error active'
}

const bindInputErrors = () => {
  requiredInputs.forEach((input) => {
    const id = input.id
    const error = document.querySelector(`#${id}-error`)
    input.addEventListener('input', function () {
      if (input.validity.valid) {
        error.textContent = ''
        error.className = 'modal__error'
      } else {
        showError(input)
      }
    })
    input.addEventListener('click', function () {
      input.classList.add('activated')
    })
  })
}

const bindSubmitErrors = () => {
  form.addEventListener('submit', function (event) {
    event.preventDefault()
    let noSubmit = false
    requiredInputs.forEach((input) => {
      if (!input.validity.valid) {
        noSubmit = true
        showError(input)
      }
    })
    if (noSubmit == true) {
      return
    } else {
      const grecaptcha = window.grecaptcha
      if (grecaptcha) {
        grecaptcha.enterprise.ready(async () => {
          // IMPORTANT: The 'token' that results from execute is an encrypted response sent by
          // reCAPTCHA Enterprise to the end user's browser.
          // This token must be validated by creating an assessment.
          // See https://cloud.google.com/recaptcha-enterprise/docs/create-assessment
          const token = await grecaptcha.enterprise.execute(
            process.env.RECAPTCHA_KEY_ID,
            { action: 'FORM_SUBMIT' }
          )

          const res = await fetch(`/api/assess?token=${token}`, {
            method: 'GET',
          }).then((res) => res.json())

          if (res.score && res.score > 0.5) {
            successfulSubmit()
          }
        })
      }
    }
  })
}

const successfulSubmit = () => {
  form.classList.add('display-none')
  formHeading.classList.add('display-none')
  const thankYou = document.createElement('div')
  thankYou.classList.add('modal__thanks')
  thankYou.setAttribute('id', 'modal-thanks')
  const thankYouTemplate = document.querySelector('#thank-you')
  const clone = thankYouTemplate.content.cloneNode(true)
  modalInner.appendChild(clone)
  const done = document.querySelector('#modal-done')
  done.addEventListener('click', hideModal)
}

export const formErrors = () => {
  createErrorMessages()
  bindInputErrors()
  bindSubmitErrors()
}
