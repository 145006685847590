const body = document.body
const modal = document.querySelector('#modal')
export const showModal = () => {
  const scrollY = window.scrollY
  body.style.position = 'fixed'
  body.style.top = `-${scrollY}px`
  modal.classList.add('visible')

  setModalHeight()

  window.addEventListener('resize', setModalHeight)
}

export const hideModal = () => {
  const scrollY = body.style.top
  body.style.position = ''
  body.style.top = ''
  window.scrollTo(0, parseInt(`${scrollY}` || '0') * -1)
  modal.classList.remove('visible')

  window.removeEventListener('resize', setModalHeight)
}

const setModalHeight = () => {
  // safari fix for setting correct vh
  modal.style.height = `${window.innerHeight}px`
}
