import { fetchStrings } from './spreadsheet-fetch'
import { scrollAnimation } from './scroll-animation'
import { marquee } from './marquee'
import { formErrors } from './form'
import { showModal, hideModal } from './modal'

const body = document.body

const CACHED_DATA = {
  intro:
    '<p>New Futures HQ is a programme designed by Hackney Quest to introduce young people to different career opportunities and be supported to build the right skills to get there.</p>\n<p>We do this by partnering with businesses, supporting them to create a range of different opportunities that let young people experience industries first hand, develop their employability skills, learn about career options and benefit from new pathways into work.</p>\n<p>Read on to find out how your organisation can get involved.</p>\n',
  'ways to get involved': '',
  'list-heading-1': 'One-off skill development opportunities',
  'list-text-1':
    'From a few hours to a few days, work with Hackney Quest to design your own opportunity for young people to come in and experience your workplace and develop a new skill.',
  'list-heading-2': 'Work experience and employment opportunities',
  'list-text-2':
    'Whether it is a few days or a few weeks, give young people the chance to come into your workplace and develop their skills while learning about your industry. Young people will be supported by Hackney Quest mentors to focus on developing their employability skills during this process.',
  'list-heading-3': 'Workplace mentoring',
  'list-text-3':
    'Individual 1:1 for young people aged 18+ from members of your workplace. Introducing young people to your world of work across anything from a one-off session to multiple sessions, depending on their interest.',
  'list-heading-4': 'Group workshops &amp; tours',
  'list-text-4':
    'Hosting workshops and tours to introduce young people to the different roles and opportunities within your workplace. Helping young people understand the skills required for each role and the pathways to get there.',
  'list-heading-5': 'Apprenticeships &amp; internships',
  'list-text-5':
    'Tailor-made trainee programs and internships for young people in your business. Giving young people the chance to truly explore your industry, build their skills and confidence, and gain the necessary experience to go on to the next step.',
  'list-heading-6': 'You tell us',
  'list-text-6':
    'Every workplace is different. So we are completely open to you bringing your own ideas and approaches on how to introduce young people to your world of work. So long as you share our passion to increase inclusivity and diversity in the workplace, please keep us informed of job opportunities you have available that young people can apply for.',
  'list-heading-7': 'Donations',
  'list-text-7':
    'We also welcome donations and are open to financial partnership with like-minded organisations to assist us in the programme development.',
  about:
    'At Hackney Quest, we have been supporting young people in the local area for over 30 years by building their skills and confidence through a range of different programmes designed to help them reach their potential.',
}

const NewFuturesApp = {
  init: () => {
    NewFuturesApp.fetchData()
    scrollAnimation()
    formErrors()

    NewFuturesApp.bindEvents()
    marquee()
  },
  fetchData: () => {
    const scroll = document.querySelector('#scroll')
    document.querySelector('html').classList.add('overflow-hidden')
    fetchStrings().then((response) => {
      if (Object.keys(response).length === 0) {
        response = CACHED_DATA
      }
      let listNumber = 0
      Object.entries(response).forEach(([key, value]) => {
        if (key.includes('list-heading-')) {
          listNumber++
          const template = document.querySelector('#list-template')
          const list = document.querySelector('#get-involved-list')
          const clone = template.content.cloneNode(true)
          const heading = clone.querySelector('h3')
          const text = clone.querySelector('p')
          heading.id = `list-heading-${listNumber}`
          text.id = `list-text-${listNumber}`
          list.appendChild(clone)
        } else {
          const targetElement = document.querySelector(`#${key}`)
          targetElement && (targetElement.innerHTML = value)
        }
      })
      scroll.classList.remove('hidden')
      document.querySelector('html').classList.remove('overflow-hidden')
      body.classList.remove('overflow-hidden')
      scrollAnimation()
      NewFuturesApp.loaded()
    })
  },
  // key down if scroll isn't available
  bindEvents: () => {
    const donateButtons = document.querySelectorAll('#partner-header, #partner')
    const close = document.querySelector('#close')
    document.addEventListener('keydown', NewFuturesApp.keyScroll)
    donateButtons.forEach((button) => {
      button.addEventListener('click', showModal)
    })
    close.addEventListener('click', hideModal)

    NewFuturesApp.resizeSplash()
    window.addEventListener('resize', NewFuturesApp.resizeSplash)
  },
  loaded: () => {
    const progress = document.querySelector('#progress')
    progress.classList.add('header__progress--loaded')
  },
  keyScroll: (e) => {
    const key = e.key
    if (key == 'Down' || key == 'ArrowDown' || key == 'Tab' || key == 'Space') {
      location.hash = '#intro-container'
    }
  },
  resizeSplash: () => {
    if (window.innerWidth < 640) {
      document.querySelector('#splash').style.height = `${
        window.innerHeight - 60
      }px`
    }
  },
}

document.addEventListener('DOMContentLoaded', NewFuturesApp.init)
